import ResponsiveImage from "../../common/responsive-image";
import { Card, tailwindClasses } from "./style";

const Banner = (props) => {
  const { title, content, heading, img, className, imgName, ext = 'jpg' } = props; // Destructuring props
  const { card, cardWrap, cardBody, cardTitle } = tailwindClasses;
  return (
    <div className={`${className} ${cardWrap}`}>
      {title && <div className={cardTitle}>{title}</div>}
      <Card className={card}>
        <div className={cardBody}>
          <h1 className="card-heading">{heading}</h1>
          <div className="card-content">{content}</div>
        </div>
        {/* {img && <img className="card-img" src={img} />} */}
        {imgName && <ResponsiveImage className="card-img" imgName={imgName} ext={ext} />}
      </Card>
    </div>
  );
};

export default Banner;
