import { useState } from "react";
import CanvasCaptcha from "../captcha";
const ContactForm = (props) => {
  const { type = 'contact', className, onClose = () => { } } = props;
  let subject = "";

  switch (type) {
    case "YOUTUBER":
      subject = "Request to register me as a Youtuber";
      break;
    case "GUIDE":
      subject = "Register me as a Guide";
      break;
    case "TRAVELER":
      subject = "Register me as a traveler";
      break;
    case "PLACE":
      subject = "Add my place to the list";
      break;
    default:
      subject = "New Contact Form Submission";
  }
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    url: "",
    subject: subject,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isCaptchError, setIsCaptchError] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch("/api/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        setResponseMessage("Form submitted successfully!");
        setFormData({ name: "", email: "", mobile: "", message: "" });
        onClose("");
      } else {
        setResponseMessage(result.message || "Failed to submit the form.");
      }
    } catch (error) {
      setResponseMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="mobile">Mobile:</label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
        </div>
        {type === "YOUTUBER" && (
          <div>
            <label htmlFor="url">Youtube url:</label>
            <input
              type="text"
              id="url"
              name="url"
              value={formData.url}
              onChange={handleChange}
              required
            />
          </div>
        )}
        <div>
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            className="h-[250px]"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <CanvasCaptcha setIsCaptchError={setIsCaptchError} />
        <button
          className={`btn m-auto block ${(isSubmitting || isCaptchError) && 'disabled'}`}
          type="submit"
          disabled={(isSubmitting || isCaptchError)}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </form>
      {responseMessage && <p className="text-[14px] mt-[8px] text-[#ff0000] leading-[18px]">{responseMessage}</p>}
    </div>
  );
};

export default ContactForm;
