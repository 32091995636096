import styled from "styled-components";

export const Footer = styled.footer`
  background: #c6e7f8;
  color: #3e0904;
  font-size: 14px;
  border-top: 4px solid #67b6cb;
  padding: 24px 0;
  .footer-links {
    .link {
      margin-bottom: 4px;
    }
  }
`;
