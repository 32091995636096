import React from "react";
import styled from "styled-components";
import { tailwindClasses } from "./tailwindClasses";

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .modalTitle {
    font-size: 21px;
  }
  .wrapper {
    width: 460px;
  }
  .modalContent ~ div {
    padding: 0 16px;
  }
`;
const Modal = ({ title, children, onClose }) => {
  const { formWrap, wrapper, modalHeader, modalContent, modalTitle } =
    tailwindClasses;
  return (
    <Wrapper className={`formWrap ${formWrap}`}>
      <div className={`wrapper ${wrapper}`}>
        <div className={`modalHeader ${modalHeader}`}>
          <div className={`modalTitle ${modalTitle}`}>
            {title}{" "}
            <span className="cursor-pointer" onClick={onClose}>
              &times;
            </span>
          </div>
        </div>
        <div className={`modalContent ${modalContent}`}>{children}</div>
      </div>
    </Wrapper>
  );
};

export default Modal;
