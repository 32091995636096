export const footerLinks = {
  column_1: [
    {
      title: "About",
      href: "/about-us",
    },
    // {
    //   title: "Feature",
    //   href: "/feature",
    // },
    {
      title: "Contact",
      href: "/contact",
    },
  ],
  // column_2: [
  //   {
  //     title: "Itinerary Plan",
  //     href: "/itinerary-plan-list",
  //     target: "_blank",
  //   },
  //   {
  //     title: "Best place to visit in Delhi",
  //     href: "/travel-destination/delhi",
  //     target: "_blank",
  //   },
  //   {
  //     title: "Best place to visit in Bengaluru",
  //     href: "/travel-destination/bengaluru",
  //     target: "_blank",
  //   },
  // ],
  column_3: [
    {
      title: "Disclaimer",
      href: "/disclaimer",
      target: "_blank",
    },
    {
      title: "Privacy Policy",
      href: "/privacy-policy",
      target: "_blank",
    },
  ],
};
