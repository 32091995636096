export const tailwindClasses = {
  testimonialCard:
    "text-center mb-[148px] md:mb-[12px] shadow-lg lg:text-[20px] tracking-[0.5px] w-[300px] md:mr-[32px] flex-[0_0_360px] bg-[#fff] rounded-[8px] fle flex-wrap",
  headings: "text-center text-[36px] mb-[28] pt-[28px] pb-[116px] md:pb-[145px] w-[100%]",
  imgClass: "w-[200px] h-[200px] rounded-[50%] m-auto mt-[-100px] absolute md:static left-[0] right-[0]",
  articalsClass:
    "bg-[linear-gradient(#c6e7f8,#67b6cb)] bg-left-bottom bg-contain pb-[18px]",
  titles:
    "title text-[22px] text-[#3E0904] border-b-[2px] border-[#58A4AE] pb-[4px] mb-[4px]",
  cardsLink:
    "card-htestimonial-cardeading link text-center flex font-medium relative mt-[40px] max-w-fit px-[38px] py-[8px] mx-[auto] mt-[16px] lg:mt-[22px] rounded-[4px] bg-[#58A4AE] text-[#fff] uppercase text-[16px] lg:text-[18px]",
};
