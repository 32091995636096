import dynamic from "next/dynamic"; // Dynamically load components
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import "../styles/globals.css";

// Dynamically load Header and Footer to optimize initial page load
const Footer = dynamic(() => import("../components/layout/footer"), { ssr: true });
const Header = dynamic(() => import("../components/layout/header"), { ssr: true });
const HeadTag = dynamic(() => import("../components/layout/head-tag"), { ssr: true });

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((registration) => {
          console.log("Service Worker registered with scope:", registration.scope);
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);

  // Simplify and sanitize the className for dynamic page styles
  const cls =
    router.asPath === "/"
      ? "home-page"
      : `internal-page ${router.asPath.replace(/\//g, " ").trim()}`;

  return (
    <>
      {/* Google Tag Manager */}
      {process.env.NEXT_PUBLIC_SERVER === "PROD" && (
        <Script
          id="google-tag-manager" // Use camelCase for the id attribute
          strategy="afterInteractive" // Ensures GTM script runs after the page becomes interactive
          differ="true"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5X339GJ8');`,
          }}
        />
      )}
      {/* End Google Tag Manager */}

      {/* Head Tag */}
      <HeadTag />

      {/* Page Content */}
      <div className={cls}>
        <Header />
        <Component {...pageProps} />
        <Footer />
      </div>
    </>
  );
}

export default MyApp;
