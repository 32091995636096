import Image from "next/image";

const Index = () => {
  return (
    <div className="lp-8" style={{ color: "#fff" }}>
      <Image width={42} height={35} src="/traviya.png" alt="logo" className="h-[35px]" />
    </div>
  );
};

export default Index;
