import styled from "styled-components";

export const ResponsiveImg = styled.div`
  /* Base hidden class: hides the component by default */
  .hidden {
    display: none;
  }

  /* Extra small screens (<= 400px) */
  @media (max-width: 400px) {
    .ssm {
      display: block;
    }
  }

  /* Small screens (> 400px and <= 600px) */
  @media (min-width: 401px) and (max-width: 600px) {
    .sm {
      display: block;
    }
  }

  /* Medium screens (> 600px and <= 768px) */
  @media (min-width: 601px) and (max-width: 768px) {
    .md {
      display: block;
    }
  }

  /* Large screens (> 768px and <= 1024px) */
  @media (min-width: 769px) and (max-width: 1024px) {
    .lg {
      display: block;
    }
  }

  /* Extra Large screens (> 1024px and <= 1280px) */
  @media (min-width: 1025px) and (max-width: 1280px) {
    .xl {
      display: block;
    }
  }

  /* double-xl screens (> 1280px) */
  @media (min-width: 1281px) {
    .double-xl {
      display: block !important;
    }
  }

    /* triple-xl screens (> 1280px) */
  @media (min-width: 1400px) {
    .triple-xl {
      display: block !important;
    }
  }
`;