import React, { useEffect, useState } from "react";
import Captcha from "react-canvas-captcha";

const CanvasCaptcha = ({
    setIsCaptchError,
    className,
}) => {
    // State variables for managing captcha generation and user input
    const [generatedCaptcha, setGeneratedCaptcha] = useState("");
    const [captchaInput, setCaptchaInput] = useState("");
    const [error, setError] = useState(false);

    // Function to handle user input for captcha
    const handleInput = (val) => {
        setCaptchaInput(val);
        if (
            val === generatedCaptcha.toUpperCase()) {
            setIsCaptchError(false);
            setError(false)
        } else {
            setIsCaptchError(true);
        }
        if (val.length < 4) {
            setError(true)
        }
    };

    // Reset captcha on component mount
    useEffect(() => {
        setCaptchaInput("");
        setIsCaptchError(true);
    }, [generatedCaptcha]);

    return (
        <div className={`flex ${className} pb-[16px] mb-[8px] relative`}>
            <Captcha
                boxHeight={44}
                boxWidth={120}
                captchaConfig={{
                    numberOfChars: 4,
                    font: "bold 23px Arial",
                    textStartingX: 15,
                    textStartingY: 5,
                    style: {
                        ...{ textTransform: "uppercase" },
                    },
                }}
                setCode={(captchaCode) => setGeneratedCaptcha(captchaCode)}
            />
            <input
                label="Captcha"
                type="text"
                value={captchaInput}
                className={"pl-[158px]"}
                onChange={(e) => {
                    handleInput(e.target.value);
                }}
                onFocus={() => setError(true)}
            />
            {error && <div className="error absolute bottom-[0] left-[4px]">Please enter valid captcha</div>}
        </div>

    );
};

export default CanvasCaptcha;
