import Head from "next/head";

function HeadTag() {
  return (
    <Head>
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="noindex" />
      {/* Additional Meta Tags (Optional) */}
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta name="language" content="en-us" />
      <link
        href="https://fonts.googleapis.com/css2?family=Trebuchet+MS&display=swap"
        rel="stylesheet"
      />
      <title>Travel with Traviya</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0,maximum-scale=5"
      />
    </Head>
  );
}

export default HeadTag;
