import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { Suspense, useEffect, useState } from "react";
import { TiSocialYoutubeCircular } from "react-icons/ti";
import ContactForm from "../../common/forms";
import Modal from "../../common/modal";
import { tailwindClasses } from "./tailwindClasses";
const Banner = dynamic(() => import("../layout/banner"), {
  suspense: true,
});

const Index = ({ homePage }) => {
  const [isForm, setIsForm] = useState(false);
  const [type, setType] = useState("");
  const [formTitle, setFormTitle] = useState("Contact Us");

  const onClose = (item = "") => {
    setIsForm(!isForm);
    setType(item);
  };
  useEffect(() => {
    if (type === "YOUTUBER") {
      setFormTitle("Register me as a Youtuber");
    } else if (type === "GUIDE") {
      setFormTitle("Register me as a Guide");
    } else if (type === "TRAVELER") {
      setFormTitle("Register my travel agency");
    } else if (type === "PLACE") {
      setFormTitle("Add my attraction near me");
    } else {
      setFormTitle("Contact Us");
    }
  }, [type]);

  const { banner, inviteToAdd, explorePlace, whyUs, mostLikedplaces } =
    homePage;
  const { testimonialCard, headings, imgClass, articalsClass, titles } =
    tailwindClasses;

  return (
    <main className="main-content">
      <div className="text-center">
        <Banner
          heading={banner?.heading}
          content={banner?.content}
          imgName="traviya_cz0fev"
        />
      </div>
      <div className="main-wrapper pt-[26px]">
        <article className="mb-[40px]">
          <h3 className="text-center text-[#3E0904] text-[36px] mb-[28px]">
            {explorePlace?.heading}
          </h3>
          <div className="text-center lg:text-[20px] tracking-[0.5px]">
            {explorePlace?.description}
          </div>
          <Link
            href="/trip"
            className="card-heading link text-center flex font-medium relative mt-[40px] max-w-fit px-[38px] py-[8px] mx-[auto] mt-[16px] lg:mt-[22px] rounded-[4px] bg-[#58A4AE] text-[#fff] uppercase text-[16px] lg:text-[18px]"
          >
            {explorePlace?.label}
          </Link>
        </article>
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <article className={articalsClass}>
          <div className="main-wrapper">
            <h3 className="text-center text-[36px] mb-[28px] pt-[28px]">
              {mostLikedplaces?.heading}
            </h3>
            <div className="row justify-center">
              {(mostLikedplaces?.data || []).map((item, i) => {
                return (
                  <div key={i} className="col-3 md:flex-[0_0_50%] md:max-w-[367px] xl:flex-[0_0_25%] xl:max-w-[367px]">
                    <Link href={item?.link}>
                      <div className="bg-[#fff] mb-[24px] rounded-[8px] overflow-hidden  md:h-[386px]">
                        <div className="max-h-[230px] md:max-h-none max-h-min md:max-h-none">
                          <Image
                            className="max-h-[230px] max-w-[100%]"
                            src={item?.img}
                            alt={item?.alt || "traviya"}
                            height={230}
                            width={329}
                          />
                        </div>
                        <div className="px-[8px] py-[8px]">
                          <div className={titles}>{item.placeName}</div>
                          <div className="title max-w-[360px] md:max-w-none line-clamp-2">
                            {item.description}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </article>
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <article className="pb-[40px]">
          <div>
            <div className="main-wrapper flex-wrap">
              <h3 className={`pb-[86px] md:pb-[86px] ${headings}`}>Our Youtube Partners</h3>
              <div className="inline-flex m-auto w-full justify-center">
                <div className={`mb-[28px] ${testimonialCard}`}>
                  {/* <img
                    className={imgClass}
                    src="/images/siddhu.jpg"
                    alt="Traveler"
                    // width={300}
                    // height={250}
                  /> */}
                  <TiSocialYoutubeCircular color='#ff0000' className="ml-auto mr-auto mt-[-50px] relative text-[100px]" />
                  <section className="card mt-[16px] leading-[1.2] p-4">
                    Welcome to all youtubers to join us and share your youtube
                    videos with us. You can also write your travel stories in
                    our youtube partners page. So do not wait and click below to
                    join/ enquiry.
                  </section>
                  <div>-Welcome: Partners-</div>
                </div>
              </div>
              <button
                onClick={() => onClose("YOUTUBER")}
                className="btn mt-[16px] md:mt-[40px] ml-auto mr-auto block"
              >
                Request enquiry
              </button >
            </div>
          </div>
        </article>
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <article className="blue-gradient pb-[28px]">
          <div className="main-wrapper">
            <h3 className="text-center text-[36px] mb-[28px] pt-[28px]">
              {inviteToAdd?.title}
            </h3>
            <div className="row mb-[18px] justify-center px-[12px] lg:justify-evenly">
              {(inviteToAdd?.cards || []).map((card, i) => {
                return (
                  <div
                    key={i}
                    className="bg-[#fff] rounded-[12px] px-[16px] py-[16px] text-center flex flex-wrap items-end justify-stretch mb-[32px] lg:mb-[0] flex-[0_0_100%] lg:flex-[0_0_30.33%]"
                  >
                    <h4 className="text-center flex-[0_0_100%] text-[#58a4ae] self-baseline border-b-2 border-[#58a4ae]">
                      {card?.title}
                    </h4>
                    <article className="descriptive self-baseline leading-normal text-[18px]">
                      {card?.descriptive}
                    </article>
                    <span
                      className="btn mt-[16px] ml-auto mr-auto block"
                      onClick={() => onClose(card?.type)}
                    >
                      {card.label}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </article>
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <article className="pb-[40px]">
          {/* <div className="main-wrapper">
            <h3 className="text-center text-[36px] mb-[28px] pt-[28px]">
              {stateWise?.heading} --
            </h3>
            <div className="text-center lg:text-[20px] tracking-[0.5px]">
              {stateWise?.description}
            </div>
            <Link
              href={stateWise?.link}
              className="card-heading link text-center flex font-medium relative mt-[40px] max-w-fit px-[38px] py-[8px] mx-[auto] mt-[16px] lg:mt-[22px] rounded-[4px] bg-[#58A4AE] text-[#fff] uppercase text-[16px] lg:text-[18px]"
            >
              {stateWise?.label}
            </Link> 
          </div> */}
          <div>
            <div className="main-wrapper flex-wrap">
              <h3 className={headings}>Top story tellars</h3>
              <div className="md:inline-flex m-auto md:w-full justify-center block w-[300px]">
                <div className={testimonialCard}>
                  <Image
                    className={imgClass}
                    src="/images/siddhu.jpg"
                    alt="Traveler"
                    width={300}
                    height={250}
                  />
                  <section className="card mt-[16px] leading-[1.2] p-4 pt-[120px] md:pt-[12px]">
                    I love to travel and share my stories to everyone throgh
                    Traviya. I love this platform where I can share my
                    experiences and chances to win exciting prizes. Also I have
                    good opportuniy to to share the best places where I received
                    the best services.
                  </section>
                  <div>-Siddhant-</div>
                </div>
                <div className={`${testimonialCard} mb-[28px]`}>
                  <Image
                    className={imgClass}
                    src="/images/traviya-user.jpg"
                    alt="Traviya user"
                    width={300}
                    height={250}
                  />
                  <section className="card mt-[16px] leading-[1.2] p-4 pt-[120px] md:pt-[12px]">
                    Writing a travel diary is my passion. Exploring new places
                    for travel is my hobby. Also I have good opportuniy to share
                    my diary with everyone via digital diary. It is fun for me
                    and chance to get some rewards.
                  </section>
                  <div>-Lokesh-</div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <article className="pb-[28px] bg-[#fff]">
          <h3 className="text-center text-[#3E0904] text-[36px] mb-[28px] pt-[28px]">
            {whyUs?.heading}
          </h3>
          <div className="main-wrapper">
            <div className="row">
              {whyUs?.data?.map((item, i) => {
                return (
                  <div key={i} className="col-6">
                    <div className="border border-[#C6E7F8] mb-[24px] rounded-[8px] px-[12px] py-[8px] min-h-min md:min-h-[200px]">
                      <div className="left"></div>
                      <div className="right">
                        <div className="title text-[22px] mb-[12px] md:mb-[0] text-[#58A4AE]">
                          {item?.why_title}
                        </div>
                        <div className="">{item?.why_description}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </article>
      </Suspense>
      {isForm && type && (
        <Modal title={formTitle} onClose={onClose}>
          <ContactForm className="w-full" type={type} onClose={onClose} />
        </Modal>
      )}
    </main>
  );
};

export default Index;
