import Image from "next/image";
import React from "react";
import { ResponsiveImg } from "./styled";

const ResponsiveImage = (props) => {
  const {
    className = '',
    prefix = "https://res.cloudinary.com/dzd08xoih/image/upload",
    imgName = "traviya-banner_m2wyep",
    ext = "jpg",
    defaultWidth = "v1728120607",
    alt = "Traviya travel",
  } = props;

  return (
    <ResponsiveImg className={className}>
      <div className="ssm hidden">
        <Image
          src={`${prefix}/w_360,h_430/${imgName}.${ext}`}
          height={450}
          width={500}
          alt={alt}
          loading="lazy"
          priority={false}
        />
      </div>
      <div className="sm hidden">
        <Image
          src={`${prefix}/w_600,h_580/${imgName}.${ext}`}
          height={500}
          width={600}
          alt={alt}
          loading="lazy"
          priority={false}
        />
      </div>
      <div className="md hidden">
        <Image
          src={`${prefix}/w_768,h_630/${imgName}.${ext}`}
          height={500}
          width={768}
          alt={alt}
          loading="lazy"
          priority={false}
        />
      </div>
      <div className="lg hidden">
        <Image
          src={`${prefix}/w_1024,h_630/${imgName}.${ext}`}
          height={500}
          width={1024}
          alt={alt}
          loading="lazy"
          priority={false}
        />
      </div>
      <div className="xl hidden">
        <Image
          src={`${prefix}/w_1180,h_630/${imgName}.${ext}`}
          height={500}
          width={1280}
          alt={alt}
          loading="lazy"
          priority={false}
        />
      </div>
      <div className="double-xl hidden">
        <Image
          src={`${prefix}/w_1200,h_630/${imgName}.${ext}`}
          height={500}
          width={3000}
          alt={alt}
          loading="lazy"
          priority={false}
        />
      </div>
      <div className="triple-xl hidden">
        <Image
          src={`${prefix}/${defaultWidth}/${imgName}.${ext}`}
          height={500}
          width={3000}
          alt={alt}
          loading="lazy"
          priority={false}
        />
      </div>

    </ResponsiveImg>
  );
};

export default ResponsiveImage;
