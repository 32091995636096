import Link from "next/link";
import CopyRight from "../copyright";
import { footerLinks } from "./constant";
import { Footer } from "./style";

const Index = () => {
  return (
    <>
      <Footer className="lp-8">
        <div className="main-wrapper">
          <div className="footer-contact pd">
            <div className="row md:flex">
              <div className="footer-links footer-col w-[50%] md:w-[25%]">
                <div className="">
                  {footerLinks.column_1.map((item, index) => {
                    return (
                      <div className="mb-[6px]" key={index}>
                        <Link
                          tabindex="0"
                          href={item.href}
                          target={item?.target}
                        >
                          {item.title}
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
              {footerLinks?.column_2?.map((item, index) => {
                return (
                  <div key={index} className="md:w-[33.33%]">
                    <div className="mb-[6px]">
                      <Link tabindex="0" href={item.href} target={item?.target}>
                        {item.title}
                      </Link>
                    </div>
                  </div>
                );
              })}
              {footerLinks.column_3.map((item, index) => {
                return (
                  <div key={index} className="md:w-[33.33%]">
                    <div className="mb-[6px]" >
                      <Link tabindex="0" href={item.href} target={item?.target}>
                        {item.title}
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="footer-social"></div>
        </div>
      </Footer>
      <CopyRight />
    </>
  );
};

export default Index;
