export const tailwindClasses = {
  formWrap:
    "fixed bg-[#000] flex justify-center items-center top-[0] left-[0] right-[0] top-[0] bottom-[0] w-full h-full  z-50",
  wrapper:
    "inset-0 rounded-[8px] bg-[#fff] overflow-hidden flex-[0_0_460px] w-[460px] max-w-[80%]",
  modalHeader: "bg-white w-11/12 max-w-md p-5  shadow-lg relative",
  modalContent:
    "flex justify-between items-center pb-2 mb-5 px-[16px] py-[16px] mb-[16px]",
  modalTitle:
    "text-[21px] font-bold m-0 flex justify-between px-[16px] py-[12px]",
};
