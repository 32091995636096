import Head from "next/head";
import Home from "../components/home-page";
import { fetchHomePageData } from "../utils/api";

export default function Index({ homePage }) {
  return (
    <div>
      <Head>
        {/* {placesTypeDetails?.[0]?.title && (
          <title>{placesTypeDetails?.[0]?.title}</title>
        )} */}

        {/* Meta Tags */}
        {homePage?.seo?.description && (
          <meta name="description" content={homePage?.seo?.description} />
        )}

        {/* Open Graph Tags */}
        {homePage?.seo?.og_title && <meta property="og:title" content={homePage?.seo?.og_title} />}
        {homePage?.seo?.og_image && <meta property="og:image" content={homePage?.seo?.og_image} />}

        {homePage?.seo?.og_description && (
          <meta property="og:description" content={homePage?.seo?.og_description} />
        )}
        {/* <meta
          property="og:url"
          content="https://www.example.com/trip/best-places-to-visit-in-pauri-garhwal"
        /> */}
        <meta property="og:type" content="website" />

        {/* Canonical Tag */}
        <link
          rel="canonical"
          href={homePage?.seo?.canonical_tag}
        />
        {homePage?.seo?.keywords && <meta name="keywords" content={homePage?.seo?.keywords} />}
      </Head>
      <Home homePage={homePage} />
    </div>
  );
}

export async function getServerSideProps(props) {
  const { req } = props;
  const isServer = req ? true : false;
  const platform = isServer ? "server" : "client";
  const homePage = await fetchHomePageData(platform);
  return {
    props: {
      homePage,
    },
  };
}
